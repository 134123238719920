(function ($) {
  Drupal.behaviors.anchorScroll = {
    attach: function () {
      /* Since we haven't found out why there is unexpected scrolling to the top of page for mobile,
      we have below code to make anchor work as expected */
      window.GlobalServiceBus.on(window.ServiceBusTopics.events.PRODUCT_FULL_LOADED, function () {
        if ($('body').hasClass('device-mobile') && window.location.hash) {
          setTimeout(function () {
            window.location.href = window.location.hash;
          }, 500);
        }
      });
    }
  };
})(jQuery);
